<script>
  import View from '@/views/View'
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'KDAPortfolioDemo',
    metaInfo: { title: 'Portfolio' },
    extends: View,

    mixins: [
      LoadSections([
        'KDAPageHeader',
        'KDAProjectDemo',
        'KDAAboutUs',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'portfolio-demo',
      },
    },

    mounted() {
      window.gtag('event', 'page_view', {
        'page_title': 'Portfolio Demo'
      })
    }, 
  }
</script>
